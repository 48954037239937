.header {
  background-color: #131921;
  width: 100%;
}

.header__container {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 100;
  max-width: 1500px;
  min-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

/* TODO: resize logo an header on scroll */
.header__title {
  flex: 0.25;
  display: flex;
}
.header__title__link {
  display: flex;
  text-decoration: none;
}
.header__title__link > h1 {
  color: #f5f5f4;
  margin-left: 10px;
  margin-top: 35px;
}

.header__link {
  text-decoration: none;
}
.header__logo {
  object-fit: contain;
  position: relative;
  top: 20px;
  height: 120px;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
}

.header__nav {
  flex: 0.65;
  display: flex;
  justify-content: space-evenly;
}

.header__option {
  display: flex;
  color: #f5f5f4;
  margin-left: 10px;
  margin-right: 10px;
  transition: color 200ms;
}

.header__option:hover {
  color: #5a9a03;
}

.header__optionIcon {
  padding: 5px;
  font-size: 40px !important;
}

.header__optionText {
  margin-top: 10px;
  font-size: 20px;
}

.header__social {
  width: 150px;
  display: flex;
  justify-content: space-evenly;
  flex: 0.1;
}

.header__socialItem {
  color: #f5f5f4;
  font-size: 40px !important;
  transition: color 200ms;
}
.header__socialItem:hover {
  color: #ff8400;
}

.header__menu {
  display: none;
}
@media only screen and (max-width: 1200px) {
  .header__nav {
    display: none;
    position: absolute;
    top: 60px;
    background-color: #131921cc;
  }
  .header__menu {
    display: block;
    flex: 0.3;
  }
  .header__title {
    flex: 0.3;
  }
  .header__social {
    flex: 0.4;
  }
  .header__title__link > h1 {
    display: none;
  }

  .header__container {
    min-width: 350px;
  }

  .header__menu {
    cursor: pointer;
  }

  .header__nav:hover {
    display: block;
  }
  .header__menu:hover ~ .header__nav {
    display: block;
  }
}
