.customer {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 350px;
  padding: 15px;
  margin: 15px;
  border: 1px solid gray;
  border-radius: 5px;
}

.customer:hover > .customer__details {
  display: block;
}

.customer__imageContainer {
  height: 100%;
}

.customer__image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.customer__details {
  display: none;
}
