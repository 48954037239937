.project {
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  padding: 10px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.project__images {
  border: 1px solid rgb(223, 221, 221);
  border-radius: 25%;
  filter: grayscale(60%);
  transition: filter 300ms;
  height: 300px;
  width: 460px;
  position: relative;
  overflow: hidden;
}

.project__image {
  width: 300px;
  object-fit: contain;
  height: 300px;
  position: relative;
}

.project__imageOne {
  width: 200px;
  top: 10px;
  left: 10px;
  object-fit: contain;
  position: absolute;
  z-index: 1;
}

.project__imageTwo {
  width: 200px;
  object-fit: contain;
  left: 100px;
  top: 120px;
  position: absolute;
  z-index: 2;
}

.project__details {
  width: 100%;
  padding: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.project:hover > .project__images {
  filter: none;
}
