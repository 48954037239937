.customers {
  max-width: 1300px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.customers__container {
  display: flex;
  flex-wrap: wrap;
}
