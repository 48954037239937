.projects {
  max-width: 1300px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.projects__container {
  display: flex;
  flex-direction: column;
}
